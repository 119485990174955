export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["SOCIAL"] = "sozial";
    SubscriptionType["NORMAL"] = "normal";
    SubscriptionType["PLUS"] = "plus";
    SubscriptionType["SUPPORTER"] = "foerder";
})(SubscriptionType || (SubscriptionType = {}));
export var SubscriptionDuration;
(function (SubscriptionDuration) {
    SubscriptionDuration["THREE_MONTHS"] = "three_months";
    SubscriptionDuration["SIX_MONTHS"] = "six_months";
    SubscriptionDuration["ONE_YEAR"] = "one_year";
})(SubscriptionDuration || (SubscriptionDuration = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["CARD"] = "card";
    PaymentMethod["PAYPAL"] = "paypal";
    PaymentMethod["SEPA"] = "sepa";
    PaymentMethod["TRANSFER"] = "transfer";
})(PaymentMethod || (PaymentMethod = {}));
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["INACTIVE"] = "inactive";
    SubscriptionStatus["ACTIVE"] = "active";
    SubscriptionStatus["CLOSED"] = "closed";
    SubscriptionStatus["TRIAL"] = "trial";
    SubscriptionStatus["CANCELED"] = "canceled";
})(SubscriptionStatus || (SubscriptionStatus = {}));
